export const phoneFormat = {
  methods: {
    phoneFormat(phoneNum=''){
      phoneNum = phoneNum.replace(/[^0-9]/g, "") //숫자만
      if(phoneNum.length < 8){
        return phoneNum.replace(/(^02.{0}|^01.{1}|\d{3})(\d+)/, '$1-$2');
      } else if(phoneNum.length == 8) {
        return phoneNum.replace(/(^02.{0}|^01.{1}|\d{4})(\d+)/, '$1-$2');
      } else{
        return phoneNum.replace(/(^02.{0}|^01.{1}|\d{3})(\d+)(\d{4})/,"$1-$2-$3");
      }
    }
  },
}