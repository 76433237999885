<template>
	<div class="contact_detail">
		<!-- 컨텐츠 START -->
		<div class="c_section contact_detail_content">
			<div class="c_inner">
				<h2>
					<!-- <img src="@/assets/images/banner/icon_contact.png" alt="아이콘"> -->
					{{this.which}}
				</h2>
				<ul class="form_list">
					<li>
						<div class="required">
							<span>이메일</span>
							<div class="input_wrapper">
								<input v-model="email" type="text" />
							</div>
						</div>
					</li>
					<li>
						<div class="required">
							<span>회사/기관명</span>
							<div class="input_wrapper">
								<input v-model="company" type="text" />
							</div>
						</div>
					</li>
					<li>
						<div class="required">
							<span>성함</span>
							<div class="input_wrapper">
								<input v-model="name" type="text" />
							</div>
						</div>
					</li>
					<li>
						<div class="required">
							<span>연락처</span>
							<div class="input_wrapper">
								<input v-model="contact" type="text" placeholder="숫자만 입력해주세요." maxlength="13" />
							</div>
						</div>
					</li>
					<!-- <li>
						<div class="file">
							<span>첨부파일</span>
							<div class="input_wrapper">
								<button @click="this.$refs.file.click()" class="c_btn_common c_btn_contrast">파일 선택</button>
								<input ref="file" @change="fileSelected($event)" type="file" style="display:none; width:0" />
								<p>{{this.file.name}}</p>
							</div>
						</div>
					</li> -->
					<li>
						<div>
							<span>상세내용</span>
							<div class="input_wrapper">
								<textarea v-model="contents" placeholder="문의하고 싶은 내용에 대해서 자유롭게 작성해 주세요."></textarea>
							</div>
						</div>
					</li>
				</ul>
				<div class="terms_text">
					<p class="title">[필수] 개인정보수집 및 이용 동의</p>
					<p class="sub_text">주식회사 위아오너는 아래의 목적으로 개인 정보를 수집 및 이용하며, 회원의 개인 정보를 안전하게 취급하는데 최선을 다합니다.</p>
					<ul>
						<li>01. 목적 : 문의 및 제안에 따른 연락처 정보 확인 </li>
						<li>02. 항목 : 이메일, 성함, 회사(기관)명, 전화번호</li>
						<li>03. 보유기간 : 문의 및 제안 상담을 위해 검토 완료 회신 후 3개월간 보관하며, 이후 해당 정보를 지체없이 파기합니다.</li>
					</ul>
					<div class="checkbox_wrapper">
						<input v-model="agree" type="checkbox" id="agree" />
						<label for="agree">개인정보 수집 및 이용에 동의합니다.</label>
					</div>
				</div>
				<div class="btn_wrapper">
					<button @click="submit" class="c_btn_common c_btn_contrast btn_submit">등록</button>
				</div>
			</div>
		</div>
		<!-- 컨텐츠 END -->
	</div>
</template>

<script>
import { phoneFormat } from '@/utils/phoneFormat'

export default {
	props: {
		which : String,
	},
	mixins: [ phoneFormat ],
	watch:{
		contact(num){
			this.contact = this.phoneFormat(num);
		}
	},
	data(){
		return {
			email: '',
			company: '',
			name: '',
			contact: '',
			// file: {},
			contents: '',
			agree : false,
		}
	},
	methods: {
		fileSelected(event){
			// 파일이 존재하는지
			if (event.target.files && event.target.files[0]) {
				// 2MB보다 크면 안받도록
				if (event.target.files[0].size > 1048576 * 10) {
					this.$store.commit("alert", "10MB 이하의 파일을 선택해 주세요");
				} else {
					this.file = event.target.files[0];
				}
			}
		},
		submit(){
			if(!this.email || !this.company || !this.name || !this.contact){
				this.$store.commit("alert", "필수 입력값을 입력해주세요");
			} else if(!this.agree){
				this.$store.commit("alert", "개인정보수집에 동의해주세요");
			} else {
				const formData = new FormData();
				formData.append('division', this.which);
				formData.append('email', this.email);
				formData.append('company', this.company);
				formData.append('name', this.name);
				formData.append('contact', this.contact);
				formData.append('site', "weareowner");
				// formData.append('file', this.file);
				formData.append('contents', this.contents);
				this.axios.post('/sendMail', formData, {
					headers: {
						identity : "DEVER_API",
						authorization : 'dcs_fd70bee5fc6b0cefd8dcc72c59a41ba2',
					}
				}).then((res)=>{
					if(res.data.success){
						this.$store.commit("alert", "문의 완료");
					} else {
						this.$store.commit("alert", "문의 도중 오류가 발생했습니다.");
					}
					this.email = ''
					this.company = '';
					this.name = '';
					this.contact = '';
					// this.file = {};
					this.contents = '';
					this.agree = false;
				}).catch((error)=>{
					console.log(error);
					this.$store.commit("alert", "문의 도중 오류가 발생했습니다.");
				})
				
			}
		}
	}
}
</script>

<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/contact_detail.css">
</style>